import { connect } from 'react-redux';
import Component from './component';

import {
    chartData,
} from 'Redux/modules/adept/measurements/actions';
import { single as getMeasurementsConfig } from 'Redux/modules/adept/measurementsConfig/actions';

export default connect(
    state => ({
        chartData: state.adeptMeasurements.chartData,
        measurementsConfig: state.adeptMeasurementsConfig.mapById,
    }),
    dispatch => ({
        actions: {
            chartData: dispatch(chartData),
            getMeasurementsConfig: dispatch(getMeasurementsConfig),
        },
    })
)(Component);
