import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { 
    EVENT_PAID_STATUS_NOT_PAID, 
    EVENT_PAID_STATUS_CREDIT,
    EVENT_DONE_STATUS_CANCELED,
    EVENT_PAID_STATUS_PAID,
    EVENT_DONE_STATUS_DONE,
} from 'Consts/events';
import { ADEPT_EVENTS_CREATE, ADEPT_EVENTS_MANAGE } from 'Consts/routes';

import { withVariables } from 'Utils/string';
import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { getPaidStatusLabel, getDoneStatusLabel } from 'Utils/event';
import { getFormattedDate, translateDateToDay } from 'Utils/date';
import { translateTimeUnit } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layout/panel/PaginatedList';
import PaginatedListElement from 'Components/layout/panel/PaginatedListElementV2';
import TextBox from 'Components/layout/panel/TextBox';
import Button from 'Components/layout/Button';

export default class AdeptEventsList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        title: PropTypes.string,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
            stats: PropTypes.func.isRequired,
            done: PropTypes.func.isRequired,
        }).isRequired,
        events: PropTypes.object.isRequired,
        stats: PropTypes.object,
        predefinedQuery: PropTypes.object,
        groups: PropTypes.bool,
        header: PropTypes.object,
        controlStyle: PropTypes.string,
    };
    static defaultProps = {
        stats: null,
        predefinedQuery: {},
        groups: false,
        header: {
            visible: true,
        },
        controlStyle: 'gradient',
    };

    state = {};

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        timestamp: undefined,
        ...(this.props.predefinedQuery || {}),  //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location, predefinedQuery } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            { ...queryObject, ...predefinedQuery },
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        const { actions } = this.props;
        actions.stats();

        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (
            JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject) ||
            JSON.stringify(prevProps.predefinedQuery) !== JSON.stringify(this.props.predefinedQuery)  //eslint-disable-line react/destructuring-assignment
        ) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    render() {
        const { events, location, title, history, stats, header, groups, controlStyle, actions } = this.props;

        const getTitle = element => {
            if(!element.startAt && !element.endAt) {
                return 'Niezaplanowany';
            }

            const startDate = element.startAt 
                ? `Data rozpoczęcia: ${getFormattedDate(element.startAt, 'date')} ${translateDateToDay(element.startAt)} ${getFormattedDate(element.startAt, 'time')}` 
                : 'Brak daty rozpoczęcia';
            
            const durationTime = element.product?.displayDurationValue
                ? `, Czas trwania: ${element.product.displayDurationValue} ${translateTimeUnit(element.product)}`
                : '';

            return `${startDate}${durationTime}`;
        };

        const elementGroupDates = {};
        const elementGroups = {};
        events.elements.forEach(element => {
            if(!elementGroupDates[getFormattedDate(element.startAt, 'date')]) {
                elementGroupDates[getFormattedDate(element.startAt, 'date')] = element.id;
                elementGroups[element.id] = getFormattedDate(element.startAt, 'date');
            }
        });

        return (
            <StyledComponent
                className="adept-events-list"
                styles={require('./styles')}
            >
                {header && header.visible && (
                    <div className="list-header">
                        <TextBox 
                            title="Wydarzenia czekające na zaplanowanie"
                            subtitle={stats && stats.data && stats.data.notPlanned && stats.data.notPlanned.value || '...'}
                            image={{
                                isVisible: true,
                                url: require('Theme/images/icons/calendar.svg'),
                            }}
                            styleVersion={2}
                        />
                        <div className="controls">
                            <NavLink to={withVariables(ADEPT_EVENTS_CREATE.path)}>
                                <Button
                                    style="gradient"
                                    size="large"
                                >
                                    Zaplanuj wydarzenie
                                </Button>
                            </NavLink>
                        </div>
                    </div>
                )}
                <PaginatedList
                    location={location}
                    history={history}
                    title={title}
                    collection={events}
                    queryConfig={this.getQueryConfig()}
                    styleVersion={2}
                    onMapElement={element => (
                        <div className="adept-events-list-wrapper" key={element.id}>
                            {groups && elementGroups[element.id] && (
                                <div className="day-group">
                                    Wydarzenia z dnia: {getFormattedDate(element.startAt, 'DD.MM.YYYY')} ({translateDateToDay(element.startAt)})
                                </div>
                            )}
                            <PaginatedListElement
                                key={element.id}
                                title={getTitle(element)}
                                subtitle={element.product && element.product.name}
                                description=''
                                labels={[{
                                    isVisible: element.paidStatus === EVENT_PAID_STATUS_PAID,
                                    label: element.userOffer.productCategory?.slug === 'masaz' 
                                        ? `Wydarzenie: ${element.order || '-'}/${element.totalCount}`
                                        : `Trening: ${element.order || '-'}/${element.totalCount}`,
                                }]}
                                additionals={[{
                                    name: 'Lokalizacja',
                                    iconSrc: require('Theme/images/icons/location.svg'),
                                    value: element.location && element.location.name || 'Brak',
                                }, {
                                    name: element.userOffer.productCategory?.slug ==='masaz' 
                                        ? 'Twój terapeuta' 
                                        : 'Twój trener',
                                    imageSrc: require('Theme/images/placeholders/image.jpg'),
                                    value: element.lead && `${element.lead.name} ${element.lead.surname}`  || 'Brak',
                                }, {
                                    name: 'Status treningu',
                                    value: [{
                                        isVisible: getPaidStatusLabel(element).key === EVENT_PAID_STATUS_NOT_PAID || getPaidStatusLabel(element).key === EVENT_PAID_STATUS_CREDIT,
                                        label: getPaidStatusLabel(element).label,
                                        state: getPaidStatusLabel(element).stateColor,
                                    }, {
                                        isVisible: getDoneStatusLabel(element).key === EVENT_DONE_STATUS_CANCELED || getDoneStatusLabel(element).key === EVENT_DONE_STATUS_DONE,
                                        label: getDoneStatusLabel(element).label,
                                        state: getDoneStatusLabel(element).stateColor,
                                    }],
                                    type: 'labels',
                                }]}
                                controls={[{
                                    type: 'button',
                                    label: 'Oznacz jako wykonane',
                                    visible: element.canDone,
                                    onClick: () => {
                                        actions.done({ id: element.id })
                                    },
                                    style: controlStyle,
                                }, {
                                    type: 'button',
                                    label: 'Otwórz',
                                    visible: true,
                                    to: withVariables(ADEPT_EVENTS_MANAGE.path, { id: element.id }),
                                    style: controlStyle,
                                }]}
                            />
                        </div>
                    )}
                />
            </StyledComponent>
        );
    }
}
