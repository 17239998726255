import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
    position: relative;
    min-height: 10em;

    .recharts-wrapper {
        width: 75%;
        background: ${variables.dpc_backgroundWhite};
        
        .recharts-legend-wrapper {
            .recharts-default-legend {
                text-align: left !important;

                .recharts-legend-item {
                    .recharts-legend-item-text {
                        color: white;
                        font-weight: 300;
                    }
                }
            }
        }
    }

    .control-board {
        width: 25%;
        padding-left: 2em;

        .board-body {
            .body-title {
                color: ${variables.dpc_fontDark};
                font-size: 1.4em;
                font-weight: 300;
            }
            .body-controls {
                margin-top: 1.5em;

                .body-control {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: .5em;
                    padding-bottom: .5em;
                    font-size: 1.1em;
                    cursor: pointer;

                    .control-icon {
                        width: 1em;
                        height: 1em;
                        background: ${variables.dpc_backgroundBlack};
                        border-radius: 360em;
                    }
                    .control-label {
                        margin-left: 1em;
                        color: ${variables.dpc_fontDark};
                        font-size: 1em;
                        font-weight: 300;
                    }

                    &.active, &:hover {
                        .control-icon {
                            background: #ea3f48;
                        }
                        .control-label {
                            color: #ea3f48;;
                        }
                    }
                }
            }
        }
    }
 

    @media (max-width: ${variables.tabletL}) {
        flex-direction: column;

        .recharts-wrapper {
            width: 100%;
        }

        .control-board {
            width: 100%;
            margin-top: 2em;

            .board-body {
                .body-controls {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;

                    .body-control {
                        width: 30%;
                    }
                }
            }
        }
    }
`;