import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        background-color: ${variables.dpc_backgroundWhite};
        min-height: 100vh;

        .list-footer {
            display: none;
        }

        .top-block {
            display: flex;

            .welcome-block {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 33%;
                margin-right: 2em;

                .logo {
                    max-width: 7em;
                    margin: 0;
                    margin-bottom: 1em;
                }

                .headline {
                    font-size: 1.4em;
                    font-weight: 700;
                    color: ${variables.dpc_fontDark};
                    margin-bottom: 1em;
                }

                .description {
                    color: #C5C5C5;
                    font-weight: 300;
                    font-size: .8em;
                }
            }

            .dashboard-user-offers-block {
                width: 100%;

                .headline {
                    color: ${variables.dpc_fontDark};
                    font-weight: 500;
                    font-size: .5em;
                }
            }
        }

        .bottom-block {
            margin-top: 1em;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .section {
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                margin-bottom: 1em;
                padding-bottom: 1em;
                border-bottom: 3px solid ${variables.dpc_borderRed};

                .section-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 1em;

                    .section-header-headline {
                        font-size: 2em;
                        color: ${variables.dpc_fontDark};
                    }
                    .section-header-control {
                        background-image: linear-gradient(to left,#DA3143,#FF6254);
                        color: #FFFFFF;
                        border-radius: .2em;
                        font-weight: 500;
                        border: 1px solid #DF3846;
                        transition: all .1s ease-in-out;
                        padding: 1em 3em;
                        text-transform: uppercase;

                        &:hover {
                            box-shadow: 0px -0.3em 0.1em rgb(0 0 0 / 30%) inset;
                        }
                    }
                }

                .section-footer {
                    display: flex;
                    justify-content: flex-end;
                    margin-top: .8em;

                    .section-footer-control {
                        text-decoration: none;
                        color: ${variables.dpc_fontDark};

                        &:hover {
                            color: #DA3143;
                        }
                    }
                }

                &:last-of-type {
                    margin-bottom: 0;
                    padding-bottom: 0;
                    border-bottom: none;
                }
            }

            .main-block {
                width: 65%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: stretch;
                border: 1px solid ${variables.dpc_borderRed};
                box-shadow: 0.00px 0.00px 4px 0px rgba(0,0,0,0.6);
                padding: 2em;

                .section-event {
                    .adept-events-list {
                        .admin-paginated-list {
                            .list-body {
                                .adept-events-list-wrapper {
                                    .admin-paginated-list-element-v2 {
                                        box-shadow: none;
                                        border: none;
                                        padding: 0;
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                }

                .section-chart {
                    .adept-measurements-chart {
                        .control-board {
                            .board-body {
                                .body-title {
                                    font-size: 1.2em;
                                }
                                .body-controls {
                                    .body-control {
                                        margin-bottom: 0;
                                        padding-bottom: 0;
                                        line-height: 1.5;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .sub-block {
                width: 31%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .section-user-offers {
                    .adept-user-offers-list {
                        .admin-paginated-list {
                            .list-header {
                                .admin-paginated-list-filterbar {
                                    border: none;
                                }
                            }
                            
                            .list-body {
                                .admin-paginated-list-element-v2 {
                                    padding: 1em;
                                    background-color: transparent;
                                    box-shadow: none;
                                    border: none;
                                    border-bottom: 3px solid ${variables.dpc_borderRed};

                                    .wrapper {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                        .block-left {
                                            width: 70%;

                                            .title-block{
                                                .title-content {
                                                    padding: 0;
                                                    margin: 0;

                                                    .title {
                                                        font-size: .9em;
                                                    }
                                                }
                                            }
                                        }
                                        .block-right {
                                            width: 30%;
                                            padding: 0;

                                            .details-block {
                                                justify-content: flex-end;

                                                .controls-block {
                                                    font-size: 1.3em;
                                                    margin: 0;
                                                    margin-left: 1em;
                                                }
                                            }
                                        }
                                    }

                                    &:last-of-type {
                                        border-bottom: none;
                                    }
                                }
                            }
                        }
                    }
                }

                .section-referral-program {
                    .headline {
                        font-size: 2em;
                        color: #CCCCCC;
                        margin: 1em 0;
                    }
                }
            }
        }

        @media (max-width: ${variables.desktopM}) {
            .bottom-block {
                
                .dashboard-events-list-blocks {
                    justify-content: space-between;

                    .main-block {
                        width: 65%;
                    }
                    
                    .sub-block {
                        .section-user-offers {
                            .adept-user-offers-list {
                                .admin-paginated-list {
                                    .list-header {
                                        .list-title {
                                            font-size: 1.8em;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: ${variables.desktopS}) {
            .top-block {
                flex-direction: column;

                .welcome-block {
                    margin-bottom: 2em;
                    width: 100%;
                }
            }

            .bottom-block {
                flex-direction: column;

                .main-block {
                    width: 100%;

                    .section-header {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        .section-header-control {
                            margin-top: 1em;
                            font-size: 1.2em;
                            width: 100%;
                            text-align: center;
                        }
                    }
                }

                .sub-block {
                    width: 100%;
                    margin-top: 3em;

                    .section {
                        .section-footer {
                            display: block;
                            justify-content: center;
                            font-size: 1.2em;
                        }
                    }
                }
            }
        }

        @media (max-width: ${variables.tabletS}) {
            .bottom-block {
                .sub-block {
                    .section-user-offers {
                        .adept-user-offers-list {
                            .admin-paginated-list {                                
                                .list-body {
                                    .admin-paginated-list-element-v2 {
                                        .wrapper {
                                            flex-direction: row;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: ${variables.tabletS}) {
            .bottom-block {
                .main-block {
                    .section-header {
                        .section-header-control {
                            width: 109%;
                        }
                    }
                }
            }
        }

        @media (max-width: ${variables.mobileM}) {
            .bottom-block {
                .main-block {
                    .section-header {
                        .section-header-control {
                            width: 112%;
                        }
                    }
                }
            }
        }

        @media (max-width: ${variables.mobileS}) {
            .bottom-block {
                .main-block {
                    .section-header {
                        .section-header-control {
                            width: 115%;
                        }
                    }
                }
            }
        }
    `;
