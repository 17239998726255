import { css } from '@emotion/core';

export default (props, state) => css`
    .admin-paginated-list-element-v2 {
        .main-block {
            border: 1px solid #DA3143;

            .control-content {
                .details-block {
                    .additionals-block {
                        .additional {
                            .additional-value {
                                margin-left: 0 !important;
                            }
                        }
                    }
                }
            }
        }
        
        &.selected {
            transform: scale(1.02);

            .main-block {
                box-shadow: rgb(0,0,0,0.2) 0px 2px 10px;
                
                .background-block {
                    filter: brightness(0.7) contrast(0.9);
                }
            }
        }
    }
`;
