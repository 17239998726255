import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';

import { withVariables } from 'Utils/string';

import { USER_OFFER_STATUS_NEW, USER_OFFER_STATUS_ACTIVE }  from 'Consts/userOffers';
import { PUBLIC_SHOP, ADEPT_EVENTS, ADEPT_DATA, ADEPT_EVENTS_CREATE, ADEPT_USER_OFFER } from 'Consts/routes';

import StyledComponent from 'Components/core/StyledComponent';
import Head from 'Components/core/Head';
import AdeptEventsList from 'Components/adept/events/List';
import MeasurementsChart from 'Components/adept/measurements/Chart';
import UserOffersList from 'Components/adept/userOffers/List';
import ReferralProgramForm from 'Components/adept/ReferralProgramForm';
import Button from 'Components/layout/Button';

export default class AdeptDashboard extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        profile: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
    };
    static defaultProps = {
        predefinedQuery: {},
    };

    render() {
        const { location, history, profile, predefinedQuery } = this.props;

        return (
            <StyledComponent 
                className="component-dashboard-adept-page" 
                styles={require('./styles')}
            >
                <Head title="Dashboard" />
                <div className="top-block">
                    <div className="welcome-block">
                        <img className="logo" src={require('Theme/images/logo-color-black.png')} />
                        <h3 className="headline">
                            Cześć {profile.name}. Witaj w panelu Adepta!
                        </h3>
                    </div>
                </div>
                <div className="bottom-block">
                    <div className="main-block">
                        <section className="section section-event">
                            <div className="section-header event-header">
                                <h2 className="section-header-headline">Wydarzenia</h2>
                                <Link className="section-header-control" to={ADEPT_EVENTS_CREATE.path}>
                                    Zaplanuj wydarzenie
                                </Link>
                            </div>
                            <AdeptEventsList
                                location={location}
                                history={history}
                                header={{
                                    visible: false,
                                }}
                                predefinedQuery={{
                                    perPage: 3,
                                    orderBy: 'future',
                                }}
                                controlStyle="hollow"
                            />
                            <div className="section-footer">
                                <Link className="section-footer-control" to={ADEPT_EVENTS.path}>
                                    Więcej wydarzeń
                                </Link>
                            </div>
                        </section>
                        <section className="section section-chart">
                            <div className="section-header event-header">
                                <h2 className="section-header-headline">Pomiary</h2>
                            </div>
                            <MeasurementsChart
                                location={location} 
                                history={history}
                                predefinedState={predefinedQuery}
                            />
                            <div className="section-footer">
                                <Link className="section-footer-control" to={withVariables(ADEPT_DATA.path, {}, { tab: 'measurementsData' })}>
                                    Więcej pomiarów
                                </Link>
                            </div>
                        </section>
                    </div>
                    <div className="sub-block">
                        <section className="section section-user-offers">
                            <div className="section-header event-header">
                                <h2 className="section-header-headline">Aktywne Zamówienia</h2>
                            </div>
                            <UserOffersList
                                location={location}
                                history={history}
                                layout="vertical"
                                onMapType={() => undefined}
                                onMapImage={() => undefined}
                                onMapControls={(element) => ([{
                                    type: 'button',
                                    visible: true,
                                    to: withVariables(ADEPT_USER_OFFER.path, { id: element.id }),
                                    style: 'hollow',
                                    size: 'small',
                                    icon: { type: 'fa', source: 'fas fa-arrow-right' },
                                }])}
                                predefinedQuery={{
                                    perPage: 3,
                                    statuses: [
                                        USER_OFFER_STATUS_NEW,
                                        USER_OFFER_STATUS_ACTIVE,
                                    ],
                                }}
                            />
                            <div className="section-footer">
                                <NavLink to={PUBLIC_SHOP.path}>
                                    <Button
                                        className="button"
                                        styleVersion="1"
                                        style="gradient"
                                        layout="fullWidth"
                                    >
                                        Przejdź do sklepu
                                    </Button>
                                </NavLink>
                            </div>
                        </section>
                        <section className="section section-referral-program">
                            <div className="section-header event-header">
                                <h2 className="section-header-headline">Zaproś znajomego</h2>
                            </div>
                            <ReferralProgramForm />
                        </section>
                    </div>
                </div>
            </StyledComponent>
        );
    }
}