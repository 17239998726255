import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Logger from 'Services/Logger';

import StyledComponent from 'Components/core/StyledComponent';
import Form from 'Components/forms/Form';
import Button from 'Components/layout/Button';
import Input from 'Components/forms/Input';

export default class AdeptReferralProgramForm extends Component {
    static propTypes = {
        onSuccess: PropTypes.func,
        actions: PropTypes.shape({
            recommendationCreate: PropTypes.func.isRequired,
        }).isRequired,
    };
    static defaultProps = {
        onSuccess: null,
    };
    state = {
        globalError: null,
        errors: {},
        isPending: false,
        isSuccess: false,
        inputStyle: 'transparentV1',
        formState: {
            email: null,
            password: null,
        },
    };

    onSubmit = () => {
        const { formState } = this.state;
        const { actions } = this.props;

        this.setState({
            isPending: true,
            errors: {},
            globalError: null,
        });

        return actions.recommendationCreate({
            name: formState.name,
            phone: formState.phone,
            email: formState.email,
            trainerName: formState.trainerName,
        })
            .then(response => {
                this.setState({ isPending: false, isSuccess: true });
                this.onSuccess(response);
            })
            .catch(error => {
                Logger.error('[RECOMMENDATION_ADEPT_FORM] Error', error);

                this.setState({
                    isPending: false,
                    errors: error?.payload?.validationErrors,
                    globalError: error?.payload?.message,
                });
            });
    }

    onSuccess = response => {
        const { onSuccess } = this.props;

        if (onSuccess) {
            return onSuccess(response);
        }

        return null;
    }

    onStateChange = (name, value) => this.setState(prevState => ({
        formState: {
            ...prevState.formState,
            [name]: value,
        },
    }))

    render() {
        const { formState, globalError, errors, isPending, isSuccess, inputStyle } = this.state;

        return (
            <StyledComponent
                styles={require('./styles')}
                className="adept-referral-program-form"
            >
                {isSuccess
                    ? (
                        <div className="success-info">
                            Dziękujemy za polecenie.
                        </div>
                    )
                    : (
                        <Form
                            data={formState}
                            onStateChange={this.onStateChange}
                            errors={errors}
                            globalError={globalError}
                            onSubmit={this.onSubmit}
                            isPending={isPending}
                        >
                            <Input
                                name="name"
                                label="Imię i Nazwisko osoby do polecenia"
                                placeholder="Imię i Nazwisko osoby do polecenia"
                                type="email"
                                style={inputStyle}
                                border='light'
                                required={true}
                            />
                            <Input
                                name="phone"
                                label="Numer telefonu osoby do polecenia"
                                placeholder="Numer telefonu osoby do polecenia"
                                type="email"
                                style={inputStyle}
                                border='light'
                                required={true}
                            />
                            <Input
                                name="email"
                                label="Adres e-mail osoby do polecenia"
                                placeholder="Adres e-mail osoby do polecenia"
                                type="email"
                                style={inputStyle}
                                border='light'
                            />
                            <Input
                                name="trainerName"
                                label="Imię i nazwisko trenera którego chcesz polecić"
                                placeholder="Imię i nazwisko trenera którego chcesz polecić"
                                type="email"
                                style={inputStyle}
                                border='light'
                            />
                            <Button
                                className="submit-button"
                                type="submit"
                                icon={{ type: 'fa', source: 'fas fa-arrow-right' }}
                                layout="fullWidth"
                                size="large"
                                style="gradient"
                            >
                                Wyślij
                            </Button>
                        </Form>
                    )}
            </StyledComponent>
        );
    }
}
