import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        display: flex;
        align-items: center;
        background: ${variables.dpc_backgroundWhite};

        &.size-fullWidth {
            width: 100%;
        }

        &.visible-false {
            display: none;
        }

        .admin-panel-box {
            .box-wrapper {
                display: flex;
                
                .block {
                    margin-top: 0;
                    padding-top: 0;
                }

                .left-block {
                    margin-right: 3em;
                    .image {
                        height: 5em;
                        width: 5em;
                    }
                }

                .right-block {
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    justify-content: center;

                    .title {
                        color: ${variables.dpc_fontDark};
                        font-size: .9em;
                        font-weight: 300;
                    }

                    .subtitle {
                        margin-top: .5em;
                        font-size: 2em;
                        font-weight: 600;
                        color: ${variables.dpc_fontDark};
                        word-break: break-word;
                    }

                    .description {
                        margin-top: .5em;
                    }
                }
            }
        }
        
        &.style-version-2 {
            min-height: 9em;
            position: relative;
            
            .admin-panel-box {
                background: none;
                width: 100%;
                box-shadow: 0px 3px 6px #00000029;

                .box-wrapper {
                    display: flex;
                    
                    .block {
                        margin-top: 0;
                        padding-top: 0;
                    }

                    .left-block {
                        display: flex;
                        align-items: center;

                        margin-right: 1.2em;
                        .image {
                            height: 4em;
                            width: 4em;
                        }
                    }
                    
                    .right-block {
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;
                        justify-content: center;

                        .title {
                            color: ${variables.dpc_fontDark};
                            font-size: .9em;
                            font-weight: 300;
                            text-transform: uppercase;
                            line-height: 1.3em;
                        }

                        .subtitle {
                            margin-top: .5em;
                            font-size: 1.5em;
                            font-weight: 800;
                            color: ${variables.dpc_fontDark};

                            &.success {
                                text-transform: uppercase;
                                color: #8ED53E;
                            }

                            &.warning {
                                text-transform: uppercase;
                                color: #fecc37;
                            }

                            &.alert {
                                text-transform: uppercase;
                                color: #f44336;
                            }

                            &.quantity {
                                color: #f44336;
                            }

                            &.info {
                                color: #DA3143;
                            }
                        }

                        .description {
                            margin-top: .5em;
                            font-weight: 400;
                            font-size: 1em;
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }
        
        &.style-version-3 {
            .admin-panel-box {
                background: none;
                .box-wrapper {
                    display: flex;
                    
                    .block {
                        margin-top: 0;
                        padding-top: 0;
                    }

                    .left-block {
                        display: flex;
                        align-items: center;

                        margin-right: 1.2em;
                        .image {
                            height: 3em;
                            width: 3em;
                        }
                    }
                    
                    .right-block {
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;
                        justify-content: center;

                        .title {
                            color: ${variables.dpc_fontDark};
                            font-size: .9em;
                            font-weight: 600;
                            text-transform: uppercase;
                            line-height: 1.3em;
                        }

                        .subtitle {
                            margin-top: .5em;
                            font-size: 2em;
                            font-weight: 800;
                            color: ${variables.dpc_fontDark};

                            &.success {
                                text-transform: uppercase;
                                color: #8ED53E;
                            }

                            &.warning {
                                text-transform: uppercase;
                                color: #fecc37;
                            }

                            &.alert {
                                text-transform: uppercase;
                                color: #f44336;
                            }

                            &.quantity {
                                color: #f44336;
                            }

                            &.info {
                                color: #DA3143;
                            }
                        }

                        .description {
                            margin-top: .5em;
                        }
                    }
                }
            }
        }

        @media (max-width: ${variables.mobileL}) {
                width: 100%;
        }
    `;
