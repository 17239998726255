import { connect } from 'react-redux';
import Component from './component';

import {
    list,
    stats,
    done,
} from 'Redux/modules/adept/events/actions';

export default connect(
    state => ({
        events: state.adeptEvents.list,
        stats: state.adeptEvents.stats,
    }),
    dispatch => ({
        actions: {
            list: dispatch(list),
            stats: dispatch(stats),
            done: dispatch(done),
        },
    })
)(Component);
