import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StyledComponent from 'Components/core/StyledComponent';
import Box from 'Components/layout/panel/Box';

export default class PanelTextBox extends Component {
    static propTypes = {
        image: PropTypes.shape({
            isVisible: PropTypes.bool,
            url: PropTypes.string,
        }),
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.any,
        description: PropTypes.string,
        visible: PropTypes.bool,
        styleVersion: PropTypes.oneOf([
            1, 2, 3,
        ]),
        stateColor: PropTypes.oneOf([
            'warning', 'success', 'alert', 'info',
        ]),
        size: PropTypes.oneOf([
            'default', 'fullWidth',
        ]),
    };

    static defaultProps = {
        image: {
            isVisible: false,
        },
        subtitle: null,
        visible: true,
        size: 'default',
    };

    render() {
        const { image, title, subtitle, description, visible, styleVersion, stateColor, size } = this.props;

        return (
            <StyledComponent
                styles={require('./styles')}
                className={classnames(
                    'admin-panel-text-box',
                    `style-version-${styleVersion}`,
                    `visible-${visible}`,
                    `size-${size}`,
                )}
            >  
                <Box>
                    <div className="box-wrapper">
                        {image && image.isVisible && (
                            <div className="block left-block">
                                <img className="image" src={image.url || require('Theme/images/placeholders/image.jpg')}/>
                            </div>
                        )}
                        <div className="block right-block">
                            <div className="title">
                                {title}
                            </div>
                            {subtitle !== null && (
                                <div className={`subtitle ${stateColor}`}>
                                    <a>{subtitle}</a>
                                </div>
                            )}
                            {description && (
                                <div className="description">
                                    {description}
                                </div>
                            )}
                        </div>
                    </div>
                </Box>
            </StyledComponent>
        );
    }
}
