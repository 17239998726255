import { connect } from 'react-redux';

import { recommendationCreate } from 'Redux/modules/user/actions';

import Component from './component';

export default connect(
    state => ({
        
    }), 
    dispatch => ({
        actions: {
            recommendationCreate: dispatch(recommendationCreate),
        },
    })
)(Component);

